import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageWrapper, Header, PageTitle, Card, Link } from "../components"

const Description = styled.p`
  margin: 0;
  font-size: 0.875rem;
  color: ${props => props.theme.colors.white};
`

const Social = styled.ul`
  margin: 0.5em 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;

  li {
    margin: 0 0 0.25rem;
    padding: 0 0 0 0.875rem;
  }

  span {
    margin-left: 0.5rem;
    display: inline-block;
  }
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          tagline
          description
          email
        }
      }
    }
  `)

  const { tagline, description, email } = data.site.siteMetadata

  return (
    <Layout>
      <SEO title="Home" />
      <PageWrapper>
        <Header>
          <Card>
            <PageTitle>{tagline}</PageTitle>
          </Card>
          <Card>
            <Description>
              <em>{description}</em>
            </Description>
          </Card>
          <Social>
            <li>
              <Link href={`mailto:${email}?subject=Hello`}>
                <FontAwesomeIcon
                  icon="envelope"
                  color="#ffffff"
                  fixedWidth
                  size="1x"
                />
                <span>{email}</span>
              </Link>
            </li>
            <li>
              <Link
                href="https://linkedin.com/in/bagustri"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={["fab", "linkedin-in"]}
                  color="#ffffff"
                  fixedWidth
                  size="1x"
                />
                <span>/bagustri</span>
              </Link>
            </li>
          </Social>
        </Header>
      </PageWrapper>
    </Layout>
  )
}

export default IndexPage
